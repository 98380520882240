import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import '../assets/css/magnific-popup.css';
import '../assets/js/jquery.magnific-popup.js';
export function ImageGallery(lsData){
    // alert(JSON.stringify(lsData));
    var appUrtPath=appGetUrlPath();

    var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}

        var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
        "<div class=\"container\">"+
            "<div class=\"row\">"+
                "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                "<div class=\"section-title heading123 text-center mb-11\">"+
                "<h2 class=\"title\">"+title+"</h2>"+
            "</div>"+
                "<div class=\"shop-bottom-area\">"+
                "<div class=\"row\">";
         var strFooter="</div></div></div></div></div></div>";
    
        var strResp="";
      for(var i=0;i<lsData.length;i++){
          let strImages=lsData[i]["col4"];
         
          if(lsData[i]["col5"]!==""){
            if (strImages!==""){
                strImages+=",";
              }
            strImages+=lsData[i]["col5"];
          }
          
          var arrImages=strImages.split(',');
          for(var j=0;j<arrImages.length;j++){
            strResp+="<div class=\"col-lg-3  col-md-6 col-sm-6 col-xs-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
            "<div class=\"product mb-5\">"+
                "<div class=\"thumb\">"+
                    "<a href=\""+appUrtPath+arrImages[j]+"\" class=\"gallery-item image\">"+
                        "<img class=\"img-thumbnail \" src=\""+appUrtPath+arrImages[j]+"\" alt=\"Product\" />"+
                        "<img class=\"img-thumbnail hover-image \" src=\""+appUrtPath+arrImages[j]+"\" alt=\"Product\" />"+
                    "</a>"+
                     "</div>"+
            "</div>"+
        "</div>";
          }
      }
      return strHeader+strResp+strFooter;
}
export default ImageGallery;